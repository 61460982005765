section.questions{
	background-color: #F5F5F5;
	max-width: 1200px;
	position: relative;
	padding: 40px 70px;
    margin: -18em auto 0;
    margin-bottom: 6em;

    @media only screen and (max-width: 1280px) {
    	margin: -8em auto 0;
    	margin-bottom: 5em;
    	max-width: calc(100% - 100px);
    }

    @media only screen and (max-width: 1024px) and (orientation: portrait){
		padding: 70px;
	}

    @media only screen and (max-width: 415px) and (orientation: portrait){
		padding: 20px;
	    max-width: calc(100% - 20px);
	}
}


section.questions form{

	> p{
		color: red;
		font-size: 14px;
		text-align: center;
		display: none;
	}

	div.progress-bar{
		p{
			font-weight: 500;
			font-size: 17px;
			color: #EB3648;
			font-style: italic;
			text-align: center;
			margin-top: 0;

			span{
				font-size: 27px;
			}
		}
		div{
			width: 80%;
			height: 14px;
			background-color: #E9E9E9;
			border-radius: 20px;
			position: relative;
			margin: 0 auto;

			span{
				background: linear-gradient(135deg,#eb3349,#f45c43);
				height: 14px;
				position: absolute;
				border-radius: 20px;
				transition: .5s;
			}
		}
		p[data-count="1"] + div span { width: 9%; }
		p[data-count="2"] + div span { width: 18%; }
		p[data-count="3"] + div span { width: 27%; }
		p[data-count="4"] + div span { width: 36%; }
		p[data-count="5"] + div span { width: 45%; }
		p[data-count="6"] + div span { width: 64%; }
		p[data-count="7"] + div span { width: 73%; }
		p[data-count="8"] + div span { width: 82%; }
		p[data-count="9"] + div span { width: 90%; }
		p[data-count="10"] + div span { width: 95%;}
		p[data-count="11"] + div span { width: 100%; }
	}

	div.questions-details {
		text-align: center;
		margin-top: 4em;
		margin-bottom: 4em;
		display: none;
	    height: 17vh;
	    position: relative;

	    .question_answer{
	        position: absolute;
		    top: 50%;
		    transform: translate(-50% , -50%);
		    left: 50%;
		    width: 85%;
			p.thanks{
				margin: 3em auto;
			}
		    input.thanks{
	    	    border: none;
			    background: #ececec;
			    padding: 20px 40px;
			    width: calc(35% - 50px);
			    margin-bottom: 50px;
			    font-family: Raleway,sans-serif;
			    font-weight: 300;
		        margin-left: 30px;

		        @media only screen and (max-width: 415px) {
		        	margin-left: 0;
		        	margin-bottom: 10px;
		        	width: 100%;
		        }
		    }
		}
		&.error .question_answer {
			textarea , input{
				border: 1px solid #eb3349;
			}
			label {
				span{
					color: #eb3349;

					&.bullet{
						border-color: #eb3349;
					}
				}
			}
		}
		@media only screen and (max-width: 1280px) {
			margin-top: 2em;
			margin-bottom: 2em;
			height: 28vh;
		}

		@media only screen and (max-width: 415px) {
			height: 45vh;
		}

		&.active {
			display: block;
		}

		p{
			font-style: italic;
			font-weight: 500;
			@media only screen and (max-width: 415px) {
				text-align: left;
			}
		}

		textarea{
		 //   	text-transform: none;
			// background: 0 0;
			// border-bottom: 2px solid #D8D8D8;
			// margin-bottom: 1.8rem;
		 //    min-height: 34px;
			// color: #555;
			// border: 0 solid;
			// box-shadow: inset 0 1px 2px transparent;
			// font-family: heroregular,Helvetica,sans-serif;
			// max-height: 34px;
			// min-width: 40%;
		 //    margin-bottom: 0;
		 //    border-bottom: 1px solid #D8D8D8;
		    border: none;
		    background: #ececec;
		    padding: 10px 10px;
		    min-height: 30px !important;
		    max-width: 70% !important;
	        min-width: 60%!important;
		    margin-bottom: 50px;
		    font-family: Raleway,sans-serif;
		    font-weight: 300;
		    margin-top: 1.5em;

	    		@media only screen and (max-width: 415px) {
					min-width: 100% !important;
	    		}
		}
		label{
			position: relative;
		    margin-right: 35px;
		    font-weight: 500;

		    @media only screen and (max-width: 415px) {
				width: 100%;
				display: block;
				margin: 0;
				text-align: left;
	    	}
			span.bullet{
            	content: "";
            	background-color: #F5F5F5;
            	border: 2px solid #A5A5A5;
            	position: absolute;
            	left: 10px;
            	top: 0;
            	width: 20px;
            	height: 20px;
            	border-radius: 20px;
            	transition: .5s;
        	    right: -25px;

    		    @media only screen and (max-width: 415px) {
					left: 0;
					top: 50%;
	    		}
			}
		}
		input[type="radio"] {  
            float: none;
            width: 36px;
            height: 36px;
			position: relative;
            top: 23px;
            margin: 0;
            opacity: 0;                                 
        }

        input[type="radio"]:checked + span {
             background: linear-gradient(135deg,#eb3349,#f45c43); 
             border: none;
        }

        &.questions-details-9 {

        	.question_answer textarea{
    		    margin: 0;
        	}
    	       @media only screen and (max-width: 1280px) {
    	       		height: 40vh;
    	       	}
        	div{
	        	width: 100%;
	        	margin-bottom: 3em;

	        	 @media only screen and (max-width: 415px) {
				    width: 85%;
				    margin-bottom: 0;
    	       	}
	        }
        }
	}

	.submit-actions{
		text-align: center;
		a , button{
			text-decoration: none;
			color: #fff;
			background: linear-gradient(135deg,#eb3349,#f45c43);
			font-style: italic;
			font-weight: 500;
			font-size: 20px;
			margin-top: 15px;
			display: inline-block;
			padding: 15px 60px 15px 40px;
			-webkit-transition: .3s ease;
			transition: .3s ease;
			position: relative;
			@media only screen and (max-width: 415px) {
				width: 80%;
			}
			&:after{
				content: "";
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%) rotate(45deg);
				-ms-transform: translateY(-50%) rotate(45deg);
				transform: translateY(-50%) rotate(45deg);
				height: 16px;
				width: 16px;
				right: 30px;
				border-top: 6px solid #fff;
				border-right: 6px solid #fff;
				-webkit-transition: .3s ease;
				transition: .3s ease;
			}
			&:hover:after{
				right: 15px;
			}

			&:nth-child(1){
				padding: 15px 40px 15px 60px;
				margin-right: 20px;
				@media only screen and (max-width: 415px) {
					margin-right: 0;
				}

				&:after{
					left: 30px;
					transform: translateY(-50%) rotate(-135deg);
				}

				 &:hover:after{
					left: 15px;
				}
			}	
		}

		button{
			display: none;
		}

	}
	.questions-details-1.active ~ .submit-actions {
	.prev{
	    background: #d8d8d8;
    	color: #B1B1B1;

    	&:after{
			border-top: 6px solid #B1B1B1;
			border-right: 6px solid #B1B1B1;	
		}
	}
}
	.questions-details-11.active ~ .submit-actions {
		.next{
			display: none;
		}
		button {
			display: inline;
			border:none;
		}
    }
	.questions-details.error ~ p {
		display: block;
    }
}