html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

::-webkit-scrollbar { 
	display: none; 
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Raleway', sans-serif;
    position: relative;
    height: 100%;
    padding-bottom: 2em;
}
ul.lang{
	position: absolute;
	li{
		color: #FFF;
		display: inline-block;
		cursor: pointer;
		opacity: .7;
		
		a {
			color: inherit;
			text-decoration: none;
		}

		&.active{
			opacity: 1;
			border-bottom: 1px solid #FFF;
		}

		&.hover{
			opacity: 1;
		}
	}
}
.admission {
	display: block; 
	margin: 3em;
	text-align: center;

	p{
		font-weight: 500;
		font-size: 24px;

		&:nth-child(2){
			font-size: 18px;
		}
	}

	a {
		position: relative;
		img{
		    max-width: 35%;
	    	margin-top: 3em;
	    	transition: .4s ease;
    		
    		@media only screen and (max-width: 7684px) and (orientation: portrait){
    			max-width: 45%;
	    	}
	    	@media only screen and (max-width: 415px){
    			max-width: 95%;
	    	}
    	}
    	span{
    		text-align: center;
		    position: absolute;
		    left: 50%;
		    bottom: 0;
		    transform: translateX(-50%);
		    color: #000;
		    font-size: 14px;
    	}
    	&:hover{
    		img{
    			transform: translateY(-10px);
    		}
    	}
	}
}

ul.grid {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	height: 100%;
	position: fixed;
	padding: 0;
	margin: 0;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	list-style: none;

		@media only screen and (max-width: 1600px) {
			max-width: calc(100% - 400px);
		}

		@media only screen and (max-width: 1024px) {
			max-width: calc(100% - 100px);
		}

		@media only screen and (max-width: 700px) {
			max-width: calc(100% - 30px);
		}

	li {
		display: inline-block;
		height: 100%;
		width: calc(100% / 6);
		float: left;
		border-left: 1px solid rgba(grey, .15);   
	}

	&:before {
		content: '';
		width: calc(100% / 6);
		height: 100%;
		position: absolute;
		border-left: 1px solid rgba(grey, .15);  
		top: 0;
		left: calc(-100% / 6);
	} 

	&:after {
		content: '';
		width: calc(100% / 6);
		height: 100%;
		position: absolute;
		border-left: 1px solid rgba(grey, .15);
		border-right: 1px solid rgba(grey, .15);  
		top: 0;
		right: calc(-100% / 6);
	} 
} 
$skew: skewY(-7.5deg);
$counter-skew : skewY(7.5deg);
section.landing {
	height: 65vh;
	width: 100vw;
	position: relative;

	@media only screen and (max-width: 1024px) {
		height: 75vh;
	}

	@media only screen and (max-width: 1024px) and (orientation: portrait){
		height: 45vh;
	}
	@media only screen and (max-width: 1024px) and (orientation: landscape){
		height: 95vh;
	}

	@media only screen and (max-width: 700px) {
		height: 100%;
		height: 90vh;
	}
	@media only screen and (max-width: 600px) {
		height: 90vh;
	}


	body.inner & {
		height: 75vh;

		@media only screen and (max-width: 700px) {
			height: 90vh;
		}
		@media only screen and (max-width: 600px) {
			height: 90vh;
		}
	}


	div.landing-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: $skew;
		transform-origin: top left;
		overflow: hidden;

		div.landing-bg-img {
			transform: $counter-skew;
			transform-origin: top left;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-image: url('/src/img/background.jpg');
			background-size: cover;
			background-position: 50% 0%;
			background-repeat: no-repeat;
			background-attachment: fixed;
			mix-blend-mode: multiply;

			@media only screen and (max-width: 410px) {
				background-size: auto 110%;
			}
		}
	}
}
div.landing-bg-content{
	position: absolute;
    max-width: 1200px;
    font-weight: 900;
    font-style: italic;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    max-width: 1200px;

    @media only screen and (max-width: 1280px) {
		max-width: calc(100% - 100px);
    }

    @media only screen and (max-width: 415px) {
    	max-width: calc(100% - 20px);
    }

	h1{
		text-transform: uppercase;
		font-size: 40px;
		font-weight: 900;
		font-style: italic;
		position: relative;
		padding-bottom: 25px;
		margin-bottom: 40px;
		color: #FFF;

		&:after{
		    content: "";
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    height: 3px;
		    width: 90px;
		    background-color: #fff;
		}
	}
}
header{
	z-index: 1;
	position: relative;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    max-width: 1200px;
    @media only screen and (max-width: 1280px) {
		max-width: calc(100% - 100px);
	}

	@media only screen and (max-width: 415px) {
		max-width: calc(100% - 20px);
	}
	img{
    position: absolute;
    left: 0;
    top: 3em;
    width: 170px;
	}
}
footer{
	position: absolute;
	bottom: 0;
	text-align: center;
	left: 50%;
	transform:translateX(-50%);
	img{
		height: 15px;
		margin-left: 20px;
		transition: .2s ease-in;

		&:hover{
			transform: translateY(-3px);
		}
	}
	p{
		font-size: 12px;
		color: #ED3A49;
	}
}